import {tokenRequest} from "../authConfig";
import {pca} from "../index";
import axios from "axios";
import {apiConfig} from "../apiConfig";

export async function getAxiosClient(activeTenant) {
    let axiosInstance = axios.create({
        baseURL: apiConfig.endpoints.baseUrl,
    });
    axiosInstance.interceptors.request.use(async function (config) {
        config.headers.Authorization = await buildAuthHeader();
        if(activeTenant) {
            config.headers["Tenant-Id"] = activeTenant.id;
        }
        return config;
    });
    return axiosInstance;
}

export async function buildAuthHeader(){
    const account = pca.getActiveAccount();

    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    try {
        const response = await pca.acquireTokenSilent({
            ...tokenRequest,
            account: account
        });

        return `Bearer ${response.accessToken}`;
    }catch (e) {
        if( e.errorCode === "interaction_required" ){
            await pca.logoutRedirect();
        }
    }
}