import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SidebarItem = props => {
    const { buttonId, tooltipPlacement, faIcon, link, tooltipText, onItemClick } = props;
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <span>
            <Link to={link} className="list-group-item list-group-item-action bg-light pointer" id={buttonId} onClick={onItemClick} >
                <FontAwesomeIcon icon={faIcon} />
            </Link>
            <Tooltip placement={tooltipPlacement} isOpen={tooltipOpen} target={buttonId} toggle={toggle} fade={false}>
                {tooltipText}
            </Tooltip>
        </span>
    );
};

export default SidebarItem;