import React from 'react';
import Zendesk from "react-zendesk";

export default function (props){
    //zendesk settings
    const setting = {
        contactForm: {
            fields: [
                { id: "name", prefill: { "*": props.instance.getActiveAccount().name } },
                { id: "email", prefill: { "*": props.instance.getActiveAccount().username } }
            ]
        }
    };

    return (<Zendesk defer zendeskKey={process.env.REACT_APP_ZENDESK_KEY}  {...setting} />);
}