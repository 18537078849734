import React, {Component} from 'react';
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardText,
    Col, Input, Form, FormGroup, Label, Nav, NavItem, NavLink,
    Row,
    TabContent, Table,
    TabPane
} from "reactstrap";
import "./GenerateQRCode.css";
import classnames from 'classnames';
import PrintQrCodeButton from "./PrintQrCodeButton";
import {apiConfig} from "../apiConfig";
import {pca} from "../index";
import DropDown from "./DropDown";
import ReactPaginate from 'react-paginate';
import DownloadQR from "./DownloadQR";
import PropagateLoader from "react-spinners/PropagateLoader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class GenerateQRCode extends Component {

    constructor(props) {
        super(props);
        this.generateQR = this.generateQR.bind(this);
        this.toggle = this.toggle.bind(this);
        this.insertRecords = this.insertRecords.bind(this);
        this.insertCommandQrRecords = this.insertCommandQrRecords.bind(this);
        this.getFarms = this.getFarms.bind(this);
        this.getWarehouses = this.getWarehouses.bind(this);
        this.getVariety = this.getVariety.bind(this);
        this.setDivision =this.setDivision.bind(this);
        this.getCommandQrDetails = this.getCommandQrDetails.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.setPageCount = this.setPageCount.bind(this);
        this.Validations = this.Validations.bind(this);


        this.state= {
            qrId: '',
            isGen: false,
            loading:true,
            description: '',
            activeTab: '1',
            buttonText: 'Create',
            printCount: '',
            qrIds:undefined,
            disableDownload: true,
            commandQrIds:[],
            selectedCommandQrs:[],
            farm:'',
            warehouse:'',
            block:'',
            room:'',
            variety:'',
            division:'',
            option:{
                "id": 1,
                "name": "Farm-Block"
            },
            options: [{
                "id": 1,
                "name": "Farm-Block"
            },{
                "id": 2,
                "name": "Warehouse-Room"
            }],
            farms :
                [{
                    "id": 0,
                    "name": "No Farms"
                }],
            warehouses :
                [{
                    "id": 0,
                    "name": "No Warehouses"
                }],
            blockDetails :
                [{
                    "id": 0,
                    "name": "No Blocks"
                }],
            roomDetails :  [{
                "id": 0,
                "name": "No Rooms"
            }],
            varietyDetails : [{
                "id": 0,
                "name": "No Varieties"
            }],
            rowDetails : [{
                "id": 0,
                "name": "No Rows"
            }],
            columnDetails : [{
                "id": 0,
                "name": "No Columns"
            }],
            soldQrDetails : [{
                "id": 0,
                "value": JSON.stringify({
                    "FARM": "",
                    "BLOCK": "",
                    "VARIETY": "",
                    "WAREHOUSE": "",
                    "ROOM": "",
                    "ROW": "",
                    "COLUMN":"",
                    "ISACTIVE": 0
                }),
                "text": "Archive QR Code",
                "text1": "",
                "fileName":"Archive QR Code"
            }],
            divisionDetails : [],
            MasterChecked: false,
            recordsPerPage:10,
            pageCount :0,
            currentPage:0,
            traceAlertToggle:false,
            traceAlertText:"Loading",
        };
    }
    // On page change handler
    handlePageClick (event) {
        this.getCommandQrDetails(event.selected);
    };
    //calculate page count
    setPageCount(length){
        this.setState({pageCount : Math.ceil(length / this.state.recordsPerPage)});
    }

    // Select/ UnSelect Table rows
    onMasterCheck(e) {
        let tempList = this.state.commandQrIds;
        let selectItems=this.state.selectedCommandQrs;
            tempList.map((item) => {
                item.selected = e.target.checked;
                if(e.target.checked===true) {
                // Check All Items
                    if (selectItems.filter(qr => qr.id === item.command_qr_id).length === 0)
                        selectItems.push(this.addSelectedRow(item));
            }
        });
        //Update State
        this.setState({
            MasterChecked: e.target.checked,
            commandQrIds: tempList,
            selectedCommandQrs: e.target.checked === true? selectItems : [],
            isGen: false,
            loading: false,
            traceAlertText: "",
            traceAlertToggle: false,
            disableDownload: (!e.target.checked)
        });
    }


    // Update List Item's state and Master Checkbox State
    onItemCheck(e, item) {
        let user = this.state.commandQrIds.find((obj => obj.command_qr_id === item.command_qr_id));
        let selectItems=this.state.selectedCommandQrs;
        let commandQrs;
        user.selected = e.target.checked;
        if(e.target.checked ===true) {
            if (selectItems.filter(qr => qr.id === item.command_qr_id).length === 0)
                selectItems.push(this.addSelectedRow(item));
                this.setState({disableDownload : false});
        }
            //Code to remove the unChecked Items
        else {
             commandQrs = selectItems.filter(commandQr => commandQr.id !== item.command_qr_id);
             if(commandQrs.length === 0)
             this.setState({disableDownload : true});
        }

        //To Control Master Checkbox State
        const totalItems = this.state.commandQrIds.length;
        const totalCheckedItems = this.state.commandQrIds.filter((e) => e.selected).length;
        // Update State
        this.setState({
            MasterChecked: totalItems === totalCheckedItems,
            selectedCommandQrs: e.target.checked === true? selectItems : commandQrs,
            isGen: false,
            loading: false,
            traceAlertText: "",
            traceAlertToggle: false
        });
    }
    // Event to add selected row
    addSelectedRow(item) {
            //Code to add the Checked Items
            let details = {};
            let farm= item.farm !==null ? item.farm.name :"";
            let block= item.block !==null ? item.block.name :"";
            let variety=item.variety!==null ? item.variety.name :"";
            let warehouse = item.warehouse !==null ? item.warehouse.name :"";
            let room = item.room !==null ? item.room.name :"";
            let row=item.division !==null ? item.division.row_id :"";
            let column =item.division !==null ? item.division.column_id :"";
            details['id']= item.command_qr_id;
            details['value'] = JSON.stringify({
                "FARM": item.farm !==null ? item.farm.id :"",
                "BLOCK": item.block !==null ? item.block.id :"",
                "VARIETY": item.variety !==null ? item.variety.id :"",
                "WAREHOUSE": item.warehouse !==null ? item.warehouse.warehouseId :"",
                "ROOM": item.room !==null ? item.room.roomId :"",
                "ROW": row,
                "COLUMN":column,
                "ISACTIVE": 1
            });
            if(item.farm !==null) {
                details['text'] = "Variety: " + variety + ";   Farm: " + farm + ";   Block: " + block;
                details['text1'] = "";
                details['fileName'] = variety + "_" + farm + "_" + block;
            } else {
                details['text'] = "Warehouse: " + warehouse +"; Room: " + room;
                details['text1'] = row !=="" && column !=="" ? "Row: " + row + "; Column: " + column : "";
                details['fileName'] = warehouse + "_" + room + "_" + row + "_" + column;
            }
        return details;
    }

    render() {
        return (
            <div className="width-mx p-4">
                <h2 className={"pb-4"}>Generate QRs</h2>
                <Alert style={{marginTop:"12px"}} isOpen={this.state.traceAlertToggle} color="warning">
                    {this.state.traceAlertText}
                </Alert>
                <div className="centre">
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '1' }) + " pointer"}
                                    onClick={() => { this.toggle('1');  }}
                                    onLoad = {() => { this.toggle('1');}}>
                                    Asset QR
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '2' }) + " pointer"}
                                    onClick={() => { this.toggle('2'); }}>
                                    Command QR
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab} className={"p-0"}>
                            <TabPane tabId="1">
                                <Row style={{width: "300px"}}>
                                    <Col sm="12">
                                        <Card className="card">
                                            { }
                                            <CardBody>
                                                <CardText>
                                                    {
                                                        <Form>
                                                            <FormGroup className={"text-left"}>
                                                                <Label for="inputPrintCount">
                                                                    Print count:
                                                                </Label>
                                                                <Input className={"text-right"} id={"inputPrintCount"} value={this.state.printCount} onChange={evt => this.updateInputValue(evt)}/>
                                                                <small>2x copies of each QR will be printed</small>
                                                            </FormGroup>
                                                        </Form>
                                                    }
                                                </CardText>
                                                <Button className="btn btn-primary floatLeft" onClick={this.generateQR} disabled={!this.state.isGen}>
                                                    {this.state.buttonText}
                                                </Button>
                                                <PrintQrCodeButton qrIds={this.state.qrIds !==undefined? this.state.qrIds : ""} isDisable={this.state.isGen} tenant={this.props.activeTenant.name}></PrintQrCodeButton>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col className={"pt-4 pb-4"}>
                                        <div className={"floatLeft"}>
                                            Options: <DropDown  id={"viewDropDown"} details={this.state.options} onSelectDetail={this.handleOption} selectedItem={this.state.option} isDisable={this.state.isGen}>Farm-Block</DropDown>
                                        </div>

                                        <div className={"floatRight"}>
                                            <DownloadQR commandQrIds={this.state.soldQrDetails} isDisable={false} flag={"S"}></DownloadQR>
                                        </div>
                                        <div className={"floatRight pr-1"} >
                                            <DownloadQR commandQrIds={this.state.selectedCommandQrs} isDisable={this.state.disableDownload} flag={"D"}></DownloadQR>
                                        </div>
                                        <div className={"floatRight"}>
                                            <Button  className={"refreshButton btn-primary mr-5"} title={"Please refresh whenever you change filters"} onClick={()=>this.toggle('2')} disabled={this.state.loading} >{this.state.loading ? <FontAwesomeIcon icon={"circle-notch"} spin /> : ""} Refresh</Button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    <div id={"scrollTable"}>
                                    <Table  className ={"table"} >
                                        <thead>
                                        <tr>
                                            <th scope={"row"}>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input-1"
                                                    checked={this.state.MasterChecked}
                                                    id="mastercheck"
                                                    onChange={(e) => this.onMasterCheck(e)}/>
                                            </th>
                                            <th>
                                                Description
                                            </th>
                                            <th>
                                                {this.state.option.id === 1? 'Variety' : ''}
                                            </th>
                                            <th>
                                                {this.state.option.id === 1? 'Farm' : 'Warehouse'}
                                            </th>
                                            <th>
                                                {this.state.option.id === 1 ?'Block' : 'Room'}
                                            </th>
                                            <th>
                                                {this.state.option.id === 2 ?'Row' : ''}
                                            </th>
                                            <th>
                                                {this.state.option.id === 2 ?'Column' : ''}
                                            </th>
                                            <th>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td></td>
                                            <td>
                                                <Input value={this.state.description} onChange={evt => this.updateDescription(evt)}/>
                                            </td>
                                            <td>
                                                {
                                                    this.state.option.id === 1 ?
                                                        <DropDown details={this.state.varietyDetails}
                                                                  onSelectDetail={this.handleVariety}
                                                                  selectedItem={this.state.variety}
                                                                  isDisable={this.state.isGen}></DropDown> : ""
                                                }
                                                </td>
                                            <td> {
                                                this.state.option.id === 1 ?
                                                    <DropDown details={this.state.farms}
                                                              onSelectDetail={this.handleFarm} selectedItem={this.state.farm} isDisable={this.state.isGen}></DropDown>
                                                    :
                                                    <DropDown details={this.state.warehouses}
                                                              onSelectDetail={this.handleWarehouse} selectedItem={this.state.warehouse} isDisable={this.state.isGen}></DropDown>
                                            }
                                            </td>
                                            <td>
                                                {
                                                    this.state.option.id === 1 ?
                                                <DropDown details={this.state.blockDetails} onSelectDetail={this.handleBlock} selectedItem={this.state.block} isDisable={this.state.isGen}></DropDown>
                                                :
                                                <DropDown details={this.state.roomDetails} onSelectDetail={this.handleRoom} selectedItem={this.state.room} isDisable={this.state.isGen}></DropDown>
                                                }
                                            </td>
                                            <td>{
                                                this.state.option.id === 2 ?
                                                <DropDown details={this.state.rowDetails} onSelectDetail={this.handleRow} selectedItem={this.state.row} isDisable={this.state.isGen}></DropDown>
                                                : ""
                                                }
                                            </td>
                                            <td>
                                                {
                                                    this.state.option.id === 2 ?
                                                <DropDown details={this.state.columnDetails} onSelectDetail={this.handleColumn} selectedItem={this.state.column} isDisable={this.state.isGen}></DropDown>
                                                : ""
                                                }
                                                </td>
                                            <td>
                                                <Button className="btn btn-primary floatLeft" disabled={this.state.loading} onClick={() => { this.setDivision();}} >
                                                    {this.state.buttonText}
                                                </Button></td>
                                        </tr>

                                        {this.state.commandQrIds !=='' ?
                                            this.state.commandQrIds.map((qr) => (
                                                    <tr key={qr.command_qr_id}>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                checked={qr.selected}
                                                                className="form-check-input-1"
                                                                id={qr.command_qr_id}
                                                                onChange={(e) => this.onItemCheck(e, qr)} />
                                                        </td>
                                                        <td>{qr.description !== null ? qr.description :"-"}</td>
                                                        <td>{this.state.option.id === 1 ?
                                                            qr.variety !== null ? qr.variety.name :"-" :""}</td>
                                                        <td>{ this.state.option.id === 1 ?
                                                              qr.farm !== null ? qr.farm.name : "-"
                                                            : qr.warehouse !== null ? qr.warehouse.name:"-"}</td>
                                                        <td>{this.state.option.id === 1 ?
                                                            qr.block !== null ? qr.block.name : "-"
                                                            :qr.room !== null ? qr.room.name :"-"}</td>
                                                        <td>{this.state.option.id === 1 ? "":
                                                            qr.division !== null ?qr.division.row_id :"-"}</td>
                                                        <td>{this.state.option.id === 1 ? "":
                                                            qr.division !== null ?qr.division.column_id :"-"}</td>
                                                        <td></td>
                                                    </tr>
                                                )):
                                            "No data to display"}
                                        </tbody>
                                    </Table>
                                    </div>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel="Next"
                                        pageRangeDisplayed={10}
                                        marginPagesDisplayed={1}
                                        onPageChange={this.handlePageClick}
                                        forcePage={this.state.currentPage}
                                        pageCount={this.state.pageCount}
                                        previousLabel="Previous"
                                        containerClassName={"paginationBttns p-0"}
                                        previousLinkClassName={"previousBttn"}
                                        nextLinkClassName={"nextBttn"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                    />
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                </div>
            </div>
        );
    }
    getFarms() {
        //call out to grab the Blocks
        this.props.axios({
            url: apiConfig.endpoints.farmBlocks.getAll,
            params: {
                tenantId: this.props.activeTenant.id
            }
        }).then(result => {
            if (result.data.length > 0) {
                this.setState({farms: result.data, farm :result.data[0],blockDetails:result.data[0].block_details, block: result.data[0].block_details[0]});
            }
        });
    }
    getWarehouses() {
        //call out to grab the Blocks
        this.props.axios({
            url: apiConfig.endpoints.warehouseDetails.getAll,
            params: {
                tenantId: this.props.activeTenant.id
            }
        }).then(result => {
            if (result.data.length > 0) {
                this.setState({warehouses: result.data, warehouse :result.data[0],roomDetails : result.data[0].room_details, room: result.data[0].room_details[0], divisionDetails: result.data[0].room_details[0].divisions});
                this.formRowDetails(result.data[0].room_details[0].divisions);
            }
        });
    }
    getVariety() {
        //call out to grab the Varieties
        this.props.axios({
            url: apiConfig.endpoints.varietyDetails.getAll,
            params: {
                tenantId: this.props.activeTenant.id
            }
        }).then(result => {
            if (result.data.length > 0) {
                let varietiesSorted = result.data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
                this.setState({varietyDetails: varietiesSorted, variety: varietiesSorted[0]});
            }
        });
    }
    getCommandQrDetails(skipPages) {
        //call out to grab the Command Qr Details
        this.props.axios({
            url: apiConfig.endpoints.commandQrDetails.getAll,
            params: {
                tenantId: this.props.activeTenant.id,
                isFarmBlock:this.state.option.id===1 ? true : false,
                skip: skipPages * this.state.recordsPerPage,
            }
        }).then(result => {
                this.setPageCount(result.data.total_records);
                this.setState({commandQrIds: result.data.data,currentPage: skipPages,traceAlertText:""},()=>this.setState({loading: false,traceAlertToggle:false}));
        });

    }
    updateInputValue(evt) {
        const val = evt.target.value;
        this.setState({
            printCount: val,
            isGen: !(val.trim().length === 0)
        });
    }

    //Description for Command QR Details
    updateDescription(evt) {
        const val = evt.target.value;
        this.setState({
            description: val
        });
    }
    generateQR(){
        const confirmBox = window.confirm("Please confirm you want to create " + this.state.printCount + " QR tracker code(s)?")
        if (confirmBox === true) {
            this.insertRecords();
        } else {
            //do nothing
        }
    }

    //handle option change
    handleOption = (optionVal) => {
        if(optionVal.id === 2)
        {
            this.setState({warehouse: "", room: "", row: "",column: "",selectedCommandQrs:[],disableDownload:true},()=>this.setState({option: optionVal},()=>this.getCommandQrDetails(0)))
        }
        else
        {
            this.setState({farm: "", block: "",selectedCommandQrs:[],disableDownload:true},()=>this.setState({option: optionVal},()=>this.getCommandQrDetails(0)));
        }
    }
    //handle farm change
    handleFarm = (farmVal) => {
        this.setState({farm: farmVal,blockDetails : farmVal.block_details,block: farmVal.block_details[0]});
    }
    //handle warehouse change
    handleWarehouse = (warehouseVal) => {
        this.setState({warehouse: warehouseVal,roomDetails : warehouseVal.room_details,room: warehouseVal.room_details[0],divisionDetails :warehouseVal.room_details[0].divisions});
        this.formRowDetails(warehouseVal.room_details[0].divisions);
    }
    //handle block change
    handleBlock = (blockVal) => {
        this.setState({block: blockVal});
    }
    //handle room change
    handleRoom = (roomVal) => {
        this.setState({room: roomVal,divisionDetails : roomVal.divisions});
        this.formRowDetails(roomVal.divisions);
    }
    //handle variety change
    handleVariety = (varietyVal) => {
        this.setState({variety: varietyVal});
    }
    //handle row change
    handleRow = (rowVal) => {
        this.setState({row: rowVal});
        this.formColumnDetails(this.state.divisionDetails, rowVal);
    }
    //handle column change
    handleColumn = (columnVal) => {
        this.setState({column: columnVal});
    }
    //Row details
    formRowDetails =(divisions)=>{
        let objRows=[];
        const uniqueRows = [...new Set(divisions.map(item => item.row_id))];
        uniqueRows.forEach(row=>{
            objRows.push({ id: row,
                "name":"Row-"+row});
        });
        this.setState({rowDetails :objRows, row : ""});
        this.formColumnDetails(divisions,objRows[0]);
    }

    //Column details
    formColumnDetails =(divisionDetails,rows)=>{
        let objColumns=[];
        let columnData= divisionDetails.filter(item=>item.row_id === rows.id);
        const uniqueColumns = [...new Set(columnData.map(item => item.column_id))];
        uniqueColumns.forEach(col=>{
            objColumns.push({ id: col,
                "name":"Column-"+col});
        });
        this.setState({columnDetails :objColumns},()=>this.setState({ column: this.state.row === ""? "": objColumns[0]}));
    }

    //Insert QR detail Records
    insertRecords(){
        //call api and get the QR ids
        try {
            this.props.axios.post(apiConfig.endpoints.saveQrCodeDetails.post, {
                "tenant_id":this.props.activeTenant.id,
                "count_of_qr_id":this.state.printCount,
                "unique_creation_id":Date.now()+"_"+pca.getActiveAccount().username
            }).then(result => {
                alert(this.state.printCount+" QR code(s) are ready to print");
                let qrVar=[];
                result.data.map((qr)=>{
                    for(let i=0;i<=1;i++){
                        qrVar.push(qr.qr_id);
                    }
                })
                this.setState({qrIds:qrVar, printCount : ''},()=>this.setState({isGen:false}));
            });
        }
        catch (e)
        {
            alert(e.message);
        }
    }

    toggle(tab){
        if(this.state.activeTab !== tab)
            this.setState({activeTab: tab});
        if(tab ==1)
            this.setState({loading: false,traceAlertToggle:false});
        else
        {
            this.getFarms();
            this.getWarehouses();
            this.getVariety();
            this.getCommandQrDetails(0);
            this.setState({traceAlertText:"Loading"},()=>this.setState({loading: true}));
        }
        this.setState({qrId:''});
    }

    //Setting division by Row and Column
    setDivision(){
        if(this.Validations()) {
            this.setState({isGen: true,traceAlertText:"Loading data"}, () => this.setState({loading: true}));
            const div = this.state.divisionDetails.filter(div => div.row_id === this.state.row.id && div.column_id === this.state.column.id);
            if (div[0] !== null && div[0] !== "undefined") {
                this.setState({division: div[0]}, () => this.insertCommandQrRecords());
            } else {
                this.insertCommandQrRecords();
            }
       }
    }
    //To check the selection of major entities
    Validations(){
        if(this.state.option.id ===1 && this.state.farm === ""){
            alert("Please select Farm to proceed");
            return false;
        }
        else if(this.state.option.id ===2 && this.state.warehouse === ""){
            alert("Please select Warehouse to proceed");
            return false;
        }
        else {
            return true;
        }
    }

    // Insert Command QR details
    insertCommandQrRecords(){
        let selectedQrs= this.state.selectedCommandQrs;
        //Add Command Qr details and call api and get the Command QR ids
        try {
            this.props.axios.post(apiConfig.endpoints.saveCommandQrDetails.post, {
                "tenant_id":this.props.activeTenant.id,
                "farm_id":this.state.farm !== undefined && this.state.option.id ===1? this.state.farm.id : "",
                "block_id":this.state.block !== undefined  && this.state.option.id ===1? this.state.block.id : "",
                "room_id":this.state.room !==undefined && this.state.option.id ===2? this.state.room.id: "",
                "variety_id" :this.state.variety !==undefined && this.state.option.id ===1? this.state.variety.id: "",
                "division_id": this.state.division !== undefined && this.state.option.id ===2? this.state.division.id : "",
                "warehouse_id":this.state.warehouse !== undefined&& this.state.option.id ===2 ? this.state.warehouse.id: "",
                "description": this.state.description
            }).then(result => {
                let items=result.data.data;
                items[0]["selected"]=true;
                //Code to hold state of selected records
                //finding index and selecting the selected record
                selectedQrs.forEach(qr=>{
                    let commandQrItem = items[items.findIndex(item=> item.command_qr_id === qr.id)];
                    if(typeof commandQrItem !== "undefined"){
                        commandQrItem["selected"] = true;
                    }
                });
                selectedQrs.push(this.addSelectedRow(items[0]));
                this.setState({commandQrIds: items,selectedCommandQrs : selectedQrs,description : '',isGen: false,
                    loading: false,
                    traceAlertText: "",
                    traceAlertToggle: false,
                    disableDownload: false});
                this.setPageCount(result.data.total_records);
            });
        }
        catch (e)
        {
            alert(e.message);
        }

    }
}
