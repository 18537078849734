import React, { useState } from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import "./TenantsDropdown.css"

const TenantsDropdown = ({tenantsData, activeTenant, setActiveTenant, setActiveFarm}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className={"tenantsDropdown text-right"}>
            <DropdownToggle color={"primary"} caret>
                {typeof activeTenant !== "undefined" ? activeTenant.name : ""}
            </DropdownToggle>
            <DropdownMenu end>
                {tenantsData.map((tenant, index) =>
                    <DropdownItem key={index} onClick={() => {
                        setActiveFarm(null);
                        setActiveTenant({id: tenant.id, name: tenant.name});
                    } } >{tenant.name}</DropdownItem>
                )}
            </DropdownMenu>
        </Dropdown>
    );
}

export default TenantsDropdown;