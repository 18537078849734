import {apiConfig} from "../../apiConfig";

export async function getTenants() {
    //let headers = await buildHeaders();
    const options = {
        method: "GET",
        //headers: headers
    };

    return fetch(apiConfig.endpoints.baseUrl + apiConfig.endpoints.tenants.getAll, options)
        .then(response => response.json())
        .catch(error => console.error(error));
}