import React, {useState} from 'react';
import "./PrereleaseFlag.css";
import {Tooltip} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function (){
    if(process.env.REACT_APP_ENVIRONMENT !== "production") {
        const [tooltipOpen, setTooltipOpen] = useState(false);
        const toggle = () => setTooltipOpen(!tooltipOpen);

        return (
            <div>
                <div id={"prerelease-flag"} className={"text-center"}>
                    <FontAwesomeIcon icon={"exclamation-triangle"}/> Pre release
                </div>
                <Tooltip placement={"bottom"} isOpen={tooltipOpen} target={"prerelease-flag"} toggle={toggle}
                         fade={false}>
                    This is a prerelease version of Track UI, meaning you get cutting edge features but may see a bug or two
                    from time to time.
                </Tooltip>
            </div>
        );
    }
}