import React, { Component } from 'react';
import "startbootstrap-simple-sidebar/dist/css/styles.css"
import "./SidebarMenu.css"
import Logo from "./Logo";

import SidebarItem from "./SidebarItem";

export class SidebarMenu extends Component {

    render () {
        return (
            <div className="row" id={"sidebar-outer"}>
                <div className={"col pr-0"}>
                    <div className="border-right shadow h-100" id="sidebar-wrapper">
                        <div className="sidebar-heading p-0">
                            <Logo/>
                        </div>
                        <div className="list-group list-group-flush">
                            <SidebarItem buttonId={"sidebar-link-searchItem"} tooltipPlacement={"right"} faIcon={"archive"} link={"/"} tooltipText={"Asset QRs"} onItemClick={this.collapseSidebar} />
                            <SidebarItem buttonId={"sidebar-link-qrcode"} tooltipPlacement={"right"} faIcon={"qrcode"} link={"/generate"} tooltipText={"Generate QRs"} onItemClick={this.collapseSidebar} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
