import React, { Component } from 'react';
import QRCode from "qrcode.react";
import {Col, Row} from "reactstrap";
import "./PrintQRCode.css";
import logo from '../images/logo.png';
export default class PrintQRCode extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (

            <div className="centre-left">
                    {
                        this.props.qrIds!==""?
                                this.props.qrIds.map((value) => {
                                    return (<div>
                                            <Row style={{paddingTop:"5%"}}>
                                                <Col>
                                                    <h5>
                                                        {value}
                                                    </h5>
                                                </Col>
                                                <Col>
                                                    <h1>
                                                        <b>{this.props.tenant}</b>
                                                    </h1>
                                                </Col>
                                            </Row>
                                            <Row style={{paddingTop:"2%"}}>
                                                <Col>
                                                    <QRCode renderAs="svg" fgColor="#928475" height="100%" width="100%" value={"%"+value+"%"} />
                                                </Col>
                                                <Col style={{paddingTop:"6%"}} >
                                                    Date : <hr></hr>
                                                    Block : <hr></hr>
                                                    Variety : <hr></hr>
                                                    Picker : <hr></hr>
                                                </Col>
                                            </Row>
                                            <Row className="centre" style={{paddingTop:"5%"}}>
                                                <Col>
                                                    <img src={logo} width="10%" alt={"Logo"} /><h5><b>---Powered by Inform AG-----</b></h5>
                                                </Col>
                                            </Row>
                                            <div className="footer"></div>
                                        </div>
                                    );
                                }
                            )
                :""
                    }
                </div>
        );
    }
}

