import React from 'react';
import QRCode from 'qrcode.react';
import {Button} from "reactstrap";

function DownloadQR({commandQrIds, isDisable,flag}) {

    // download QR code
    const downloadQRCode = () => {
        commandQrIds.forEach((qr)=>{
            let qrCodeURL = document.getElementById('qrCodeEl'+qr.id);
            let canvas = document.getElementById("genericCanvas");
            let context = canvas.getContext("2d");
            context.canvas.width  = 600;
            context.canvas.height = 600;
            context.drawImage(qrCodeURL, 5, 12.5);
            context.fillText(qr.text, 120, 25);
            context.fillText(qr.text1, 120, 45);
            let aEl = document.createElement("a");
            aEl.href = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
            aEl.download = qr.fileName+".png";
            document.body.appendChild(aEl);
            aEl.click();
            document.body.removeChild(aEl);
        });
    }

    const isMac = typeof window !== 'undefined' ? ((navigator.platform.toUpperCase().indexOf("MAC") >= 0) || (navigator.platform.toUpperCase().indexOf("IOS") >= 0) || (navigator.platform.toUpperCase().indexOf("AND") >= 0)): false;

    return (
        <div>
            <Button className="btn btn-primary floatLeft"  disabled={isDisable} onClick={downloadQRCode}>
                {flag=== 'D' ? 'Download':'Download Archive QR'}
            </Button>
            <div style={{display:"none"}}>
                <canvas id={"genericCanvas"}></canvas>
        {commandQrIds !== "undefined"?
            commandQrIds.map((id, index) =>
                <QRCode
                    key={"qrCodeEl"+id.id}
                    id={"qrCodeEl"+id.id}
                    size= {isMac ? 300 : 600}
                    value={"%"+id.value+"%"}
                    level={"H"}
                    includeMargin={true}
                />
            ): ""
        }
            </div>
        </div>
    );
}

export default DownloadQR;