import React from 'react';
import { ListGroup, ListGroupItem, Container, Row, Col } from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleRight} from "@fortawesome/free-solid-svg-icons";
import './TenantSelectionMenu.css';

export default function TenantSelectionMenu({tenantsData, setActiveTenant}){
    return (
        <Container>
            <Row style={{height: '70vh'}}>
                <Col sm={{size: 10, offset: 1}} lg={{size: 6, offset: 3}}  md={{ size: 8, offset: 2 }} className={"div-wrapper d-flex justify-content-center align-items-center w-100"}>
                    <div className={"d-block"}>
                        <div className={"w-100 text-center"}>
                            <img src="images/logos-03.png" width="280" alt={"Inform Logo"} />
                        </div>
                        <ListGroup className={"shadow"}>
                            <ListGroupItem disabled>
                                <div className={"d-flex w-100 justify-content-between"}>
                                    <h5 className="mb-1">Select your Tenant</h5><br />
                                </div>
                                <small>Once selected, you will be redirected to the irrigation management
                                    screen.</small>
                            </ListGroupItem>
                            {tenantsData.map((tenant) =>
                                <ListGroupItem key={tenant.id} className={"login-tenant-select-item pointer"} onClick={() => setActiveTenant({id: tenant.id, name: tenant.name})}>
                                    {tenant.name} <FontAwesomeIcon icon={faArrowCircleRight} className={"d-none pull-right mt-1"} />
                                </ListGroupItem>
                            )}
                        </ListGroup>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}