import React from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import {apiConfig} from "../apiConfig";
import {Get} from "react-axios";
import ErrorAlert from "./ErrorAlert";
import {SidebarMenu} from "./SidebarMenu";

//add all solid icons to library for use throughout whole app
library.add(fas);

function MainLayout(props) {
    return (
        <Get url={apiConfig.endpoints.farms.getAll} activeTenant={props.activeTenant}>
            {(error, response, isLoading, makeRequest, axios) => {
                if(error) {
                    return (<div>Something bad happened: {error.message} <button onClick={() => makeRequest({ params: { reload: true } })}>Retry</button></div>)
                }
                else if(isLoading) {
                    return (<div>Collecting your farms...</div>)
                }
                else if(response !== null) {
                    if(response.data.length > 0) {
                        props.setFarmsData(response.data);
                        props.setActiveFarm((props.activeFarm === null ? 0 : props.activeFarm));
                        return (<div className="d-flex">
                            <SidebarMenu farmsData={props.farmsData} activeFarm={props.activeFarm}/>
                            <div id="page-content-wrapper" className="d-flex flex-column">
                                <NavMenu tenantsData={props.tenantsData} activeTenant={props.activeTenant} setActiveTenant={props.setActiveTenant} farmsData={props.farmsData} activeFarm={props.activeFarm} setActiveFarm={props.setActiveFarm}/>
                                <Container fluid={true} className={"p-0 flex-grow-1"}>
                                    {props.children}
                                </Container>
                            </div>
                        </div>)
                    }else{
                        return (<ErrorAlert message={<div>No farms found, please <a href="mailto:support@informag.com.au">contact support</a> for further assistance.</div>} actionDisplayName={"Retry"} actionMethod={() => makeRequest({ params: { reload: true } })} />)
                    }
                }
                return (<div>About to collect your farms...</div>)
            }}
        </Get>
    );
}

export default MainLayout;
