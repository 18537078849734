import * as React from "react";
import { useReactToPrint } from "react-to-print";
import PrintQRCode from "./PrintQRCode";
import {Button} from "reactstrap";
import "./PrintQRCode.css";

export const PrintQrCodeButton = (data) => {
    const componentRef = React.useRef(null);
    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle:data.qrIds,
        removeAfterPrint: true,
        pageStyle :` @media print {
                        @page { 
                        size: landscape; 
                        }}`
    });
    const onClick = (() => {
       // alert('printing');
    });

    return (
        <div>
            <Button className="btn btn-primary floatLeft" style={{float: "right"}} disabled={data.isDisable} onClick={() => { handlePrint(); onClick();}}>
                Print
            </Button>
        <div style={{display:"none"}}>
            <PrintQRCode qrIds={data.qrIds} ref={componentRef} tenant={data.tenant}/>
        </div>
        </div>
    );
};
export default PrintQrCodeButton;
