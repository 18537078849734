import React from "react";
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

class DropDown extends React.Component {
    state = {
        showItems: false,
        selectedItem: this.props.selectedItem
    };

    toggleOpen = () => {
        this.setState(prevState => ({
            showItems: !prevState.showItems
        }));
    };

    toggleBlockDropdown = detail => {
        if (detail.id !== 0) {
        this.setState({selectedItem: detail});
        this.props.onSelectDetail(detail);
         }
    };

    componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.selectedItem !== this.state.selectedItem)
        {
            this.setState({selectedItem: this.props.selectedItem})
        }
    }

    render() {
        return (
            <Dropdown isOpen={this.state.showItems} toggle={this.toggleOpen} className="btn">
                    <DropdownToggle color={"primary"} disabled={this.props.isDisable} caret>
                        {this.state.selectedItem !== "" && this.state.selectedItem ?
                                this.state.selectedItem.name : "--Select--"
                        }
                    </DropdownToggle>
                    <DropdownMenu end>
                        {this.props.details.map((detail, index) =>
                            <DropdownItem key={index} onClick={() => {this.toggleBlockDropdown(detail)}}>{detail.name}</DropdownItem>
                        )}
                    </DropdownMenu>
                </Dropdown>
        );
    }
}

export default DropDown;
