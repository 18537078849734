import React, {Component} from 'react';
import "./Search.css"
import {Table} from "semantic-ui-react";

import {apiConfig} from "../apiConfig";
import {Alert, Badge, Button, Input, Label} from "reactstrap";

import Multiselect from 'multiselect-react-dropdown';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropagateLoader from "react-spinners/PropagateLoader";
import Modal from 'react-modal';
import ExcelDataDownload from "./ExcelDataDownload";
import {useJsonToCsv} from 'react-json-csv';
import ReactPaginate from "react-paginate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
export default class Search extends Component {

    constructor(props) {
        super(props);
        this.loadData = this.loadData.bind(this);
        //move this to root component
        this.getMetaData = this.getMetaData.bind(this);
        this.resetTokenMgr=this.resetTokenMgr.bind(this);
        this.resetFilters=this.resetFilters.bind(this);
        this.showAndHideFilters=this.showAndHideFilters.bind(this);
        this.dateOnChange=this.dateOnChange.bind(this);
        this.handleOpenHistoricalModal=this.handleOpenHistoricalModal.bind(this);
        this.handleCloseHistoricalModal=this.handleCloseHistoricalModal.bind(this);
        this.downloadAsCSV=this.downloadAsCSV.bind(this);
        this.downloadCSVCallBack=this.downloadCSVCallBack.bind(this);
        this.formRoomText=this.formRoomText.bind(this);

        this.farmFilter=React.createRef();
        this.blockFilter = React.createRef();
        this.varietyFilter = React.createRef();
        this.warehouseFilter = React.createRef();
        this.roomFilter = React.createRef();
        this.rowFilter = React.createRef();
        this.columnFilter = React.createRef();

        this.state= {
            uniqueItems:[],
            excelData : [],
            totalPages:0,
            currentPage:0,
            recordsPerPage:100,
            tokenMgr:null,
            farmDetails:[],
            blockDetails :  [""] ,
            roomDetails :  [""],
            wareHouseDetails:[""],
            varietyDetails : [""],
            rowDetails :  [""],
            columnDetails : [""],
            loadMetaData:true,
            block:null,
            room:null,
            variety:null,
            open:"none",
            toggleFilterText:'Show filters',
            loading:false,
            hasChanges:false,
            startDate:null,
            endDate:null,
            includeArchived:false,
            showHistoricalModal:false,
            dataForQr:[],
            windowWidth:window.innerWidth,
            totalRecords:0,
            traceAlertToggle:false,
            traceAlertText:"",
            styleForMultiSelect:{
                chips: {
                    background: '#928475'
                },
                multiselectContainer: {
                    color: '#000',
                    paddingTop:'10px'
                },
                searchBox: {
                    color: '#fff !important',
                    backgroundColor: '#928475 !important',
                    borderColor: '#928475 !important',
                }
            },
            excelLoading:false
        }


    };

    formRoomText(room, row, column){
        let concatText=room;
        concatText+=row!=null && row!=="" ?', Row: '+row : "";
        concatText+=column!=null && column!==""?', Column: '+column : "";
        return concatText;
     }

    async downloadAsCSV(){
        this.setState({traceAlertText:"Starting your download"},()=>this.setState({traceAlertToggle:true}));
            await  this.props.axios.post(apiConfig.endpoints.QrCodeDetails.post,{
                "metaData":false, //can be ""
            "block":this.blockFilter.current.getSelectedItems(), //if block filter is selected
            "room":this.roomFilter.current.getSelectedItems(), //if room filter is selected
            "wareHouse":this.warehouseFilter.current.getSelectedItems(),
            "variety":this.varietyFilter.current.getSelectedItems(), //if variety filter is selected
            "farm":this.farmFilter.current.getSelectedItems(),
            "row":this.rowFilter.current.getSelectedItems(), //if variety filter is selected
            "column":this.columnFilter.current.getSelectedItems(),
            "startDate":this.state.startDate,
            "endDate":this.state.endDate,
            "offset":0,
            "excel":1,
            "includeArchived":!this.state.includeArchived ?this.state.includeArchived :null
            }).then(result=>{
                this.setState({excelData:result.data.excel_data},()=>this.downloadCSVCallBack());
        }).catch(error=>{console.error("IAG-ERROR] Error downloading Data:" + error.message)});
    }
    downloadCSVCallBack(){
        const filename = 'RawData_'+Date(),
            fields = {
                "variety_name": "Variety",
                "qr_id":"QR ID",
                "farm_name": "Farm",
                "last_warehouse_name": "Last Warehouse",
                "block_name":"Block",
                "last_room_name": "Last Room",
                "last_row": "Last Row",
                "last_column": "Last Column",
                "picked_date":"Pick Date",
            },
            data = this.state.excelData;

        const { saveAsCsv } = useJsonToCsv();
        saveAsCsv({ data, fields, filename });
        window.setTimeout(()=>{
            this.setState({traceAlertToggle:false,traceAlertText:""});
        },2000)

    }
componentDidMount() {
    this.loadData(0);

    window.addEventListener('resize',()=> this.setState({windowWidth:window.innerWidth}));
   }

    dateOnChange(dates){
        this.resetTokenMgr();
        const [start, end] = dates;
        if(end){
            if(start.toString()===end.toString())
                end.setDate(start.getDate()+1);
        }

        this.setState({startDate:start,endDate:end});
    }

    getMetaData(metaData) {
        this.setState({loading:true});
        let farmDetails=[];

        if(this.props.farmsData)
        {
            this.props.farmsData.forEach(data=>{
                let farmDetailsJson={};
                    farmDetailsJson["name"]=data.name;
                    farmDetailsJson["id"]=data.id;
                    farmDetails.push(farmDetailsJson);
            });
        }
        this.setState({farmDetails:farmDetails,blockDetails: metaData.block,roomDetails:metaData.room,varietyDetails:metaData.variety,rowDetails:metaData.row,columnDetails:metaData.column,wareHouseDetails:metaData.wareHouse  },()=> this.setState({loading:false,loadMetaData:false}));

    }

resetFilters() {
    this.varietyFilter.current.resetSelectedValues().then(r => {
        this.blockFilter.current.resetSelectedValues().then(r => {
            this.roomFilter.current.resetSelectedValues().then(r => {
                this.warehouseFilter.current.resetSelectedValues().then(r => {
                    this.farmFilter.current.resetSelectedValues().then(r => {
                        this.rowFilter.current.resetSelectedValues().then(r => {
                            this.columnFilter.current.resetSelectedValues().then(r => {
                                this.setState({startDate: null, endDate: null});
                                this.loadData(0);
                            });
                        });
                    })
                })
            })
        })
    });
}

resetTokenMgr() {
    this.setState({tokenMgr:null,hasChanges:true})
}

    showAndHideFilters(){
        let filter = document.getElementById("filterViewButton");
        let refresh = document.getElementById("refreshButtonView");

        filter.style.display==="block"?   filter.style.display="none" : filter.style.display="block";

        if(this.state.toggleFilterText==="Show filters")
        {
            this.setState({toggleFilterText:"Hide filters"});
            refresh.classList.add("d-none");
        }
        else {
            this.setState({toggleFilterText: "Show filters"});
            refresh.classList.remove("d-none");
        }
    }

loadData(pageNumber){
    this.setState({traceAlertText:"Loading data"},()=>this.setState({loading:true}) );

    // call api
      this.props.axios.post(apiConfig.endpoints.QrCodeDetails.post,{
        "metaData":true, //can be "" or token
        "block":this.blockFilter.current.getSelectedItems(), //if block filter is selected
        "room":this.roomFilter.current.getSelectedItems(), //if room filter is selected
        "wareHouse":this.warehouseFilter.current.getSelectedItems(),
        "variety":this.varietyFilter.current.getSelectedItems(), //if variety filter is selected
        "farm":this.farmFilter.current.getSelectedItems(),
        "row":this.rowFilter.current.getSelectedItems(), //if variety filter is selected
        "column":this.columnFilter.current.getSelectedItems(),
        "startDate":this.state.startDate,
        "endDate":this.state.endDate,
        "offset":pageNumber*100,
        "excel":0,
          "includeArchived":!this.state.includeArchived ?this.state.includeArchived :null
        }).then(result => {
        //first thing, set the dataset
        this.setState({uniqueItems:result.data.data});

   // if(this.state.loadMetaData)
          this.getMetaData(result.data.meta_data);


        //page number stuff
        let totalRecords=result.data.total_records;
        let totalPages=Math.ceil(totalRecords/this.state.recordsPerPage);


        this.setState({totalRecords:totalRecords,totalPages:totalPages,currentPage:pageNumber,hasChanges:false},()=>this.setState({loading:false,traceAlertToggle:false,traceAlertText:""}))
        }).catch(error => {

          this.setState({tokenMgr:null,uniqueItems:[],totalRecords:0,totalPages:[],hasChanges:false},()=>this.setState({loading:false,traceAlertToggle:true,traceAlertText:"Couldn't find any data"}))

        console.error("[IAG-ERROR] Error loading Data: " + error.message)
    })
}

    handleOpenHistoricalModal(qrId){
        //this.setState({traceAlertText:"Loading data"},()=>this.setState({traceAlertToggle:true}) );
        //call out to grab the Blocks
        this.props.axios({
            url:apiConfig.endpoints.QrCodeDetails.get+qrId
        }).then(result => {
            this.setState({ dataForQr:result.data},()=> this.setState({showHistoricalModal:true,traceAlertToggle:false,traceAlertText:""}));
        });
    }

    handleCloseHistoricalModal(){
        this.setState({ showHistoricalModal: false},()=> this.setState({dataForQr:[]}) );
    }



    render() {
        return (
            <div id={"rootDiv"} className={"pl-3 pr-3 pt-2"} >
                <h2 className={"pb-3"}>Asset QRs</h2>

                <div className={"totalRecordsFound"}>
                    <span>{this.state.totalRecords} record{this.state.totalRecords > 1 || this.state.totalRecords === 0 ? "s" : ""} found</span>
                </div>
                  <Alert isOpen={this.state.traceAlertToggle} color="warning" >
                      {this.state.traceAlertText}
                  </Alert>
                <ExcelDataDownload  loading={this.state.loading } downloadAsCSV={this.downloadAsCSV}> </ExcelDataDownload>
                <Button className={"btn-primary float-right"}  onClick={()=>this.showAndHideFilters()} disabled={this.state.loading} >{this.state.toggleFilterText} </Button>
                <div id={"refreshButtonView"} className={"float-right pb-2 mr-5"}>
                    <Button title={"Please refresh whenever you change filters"} className={"refreshButton btn-primary"} disabled={this.state.loading} onClick={()=>this.loadData(0)} >{this.state.loading ? <FontAwesomeIcon icon={"circle-notch"} spin /> : ""} Refresh </Button>
                </div>
             <div  id={"filterViewButton"}  className={"pt-5"} style={{display:"none"}} >

    <Multiselect style={this.state.styleForMultiSelect}
                 id={"farmFilter"}
                 displayValue="name"
                 ref={this.farmFilter}
                 onSelect={()=>this.resetTokenMgr()}
                 onRemove={()=>this.resetTokenMgr()}
                 options={this.state.farmDetails}
                 placeholder="Filter farms"
                 showCheckbox />
    <DatePicker selected={this.state.startDate}
                id={"dateFilter"}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={(dates)=>this.dateOnChange(dates)}
                placeholderText="Select a specific date range"
                isClearable={true}
                selectsRange  />

    <Multiselect style={this.state.styleForMultiSelect}
                                          displayValue="name"
                                          ref={this.blockFilter}
                                          onSelect={()=>this.resetTokenMgr()}
                                          onRemove={()=>this.resetTokenMgr()}
                                          options={this.state.blockDetails}
                                          placeholder="Filter blocks"
                                          showCheckbox />
                 <Multiselect style={this.state.styleForMultiSelect}
                              displayValue="name"
                              ref={this.varietyFilter}
                              options={this.state.varietyDetails}
                              onSelect={()=>this.resetTokenMgr()}
                              onRemove={()=>this.resetTokenMgr()}
                              placeholder="Filter varieties"
                              showCheckbox/>
                 <Multiselect style={this.state.styleForMultiSelect}
                              displayValue="name"
                              ref={this.warehouseFilter}
                              options={this.state.wareHouseDetails}
                              onSelect={()=>this.resetTokenMgr()}
                              onRemove={()=>this.resetTokenMgr()}
                              placeholder="Filter warehouses"
                              showCheckbox/>
                             <Multiselect style={this.state.styleForMultiSelect}
                                          displayValue="name"
                                          ref={this.roomFilter}
                                          options={this.state.roomDetails}
                                          onSelect={()=>this.resetTokenMgr()}
                                          onRemove={()=>this.resetTokenMgr()}
                                          placeholder="Filter rooms"
                                          showCheckbox/>
                 <Multiselect style={this.state.styleForMultiSelect}
                              id={"rowFilter"}
                              isObject={false}
                              ref={this.rowFilter}
                              options={this.state.rowDetails}
                              onSelect={()=>this.resetTokenMgr()}
                              onRemove={()=>this.resetTokenMgr()}
                              placeholder="Filter rows"
                              showCheckbox/>
                 <Multiselect style={this.state.styleForMultiSelect}
                                                id={"columnFilter"}
                                                isObject={false}
                                                ref={this.columnFilter}
                                                options={this.state.columnDetails}
                                                onSelect={()=>this.resetTokenMgr()}
                                                onRemove={()=>this.resetTokenMgr()}
                                                placeholder="Filter columns"
                                                showCheckbox/>
                 <div className={"pl-4 marginTopDiv"} style={{fontSize:"14px"}}>  <Label style={{width:"100%"}} check>
                     <Input   onChange={(e) =>    this.setState({includeArchived: e.target.checked})}  checked={this.state.includeArchived} type="checkbox" />{' '}
                     Show archived records
                 </Label></div>
                 <div style={{paddingTop:"10px"}}> </div>

                <div className={"text-right pb-2"}>
                    <Button className={"refreshButton btn-primary mr-1"} title={"Please refresh whenever you change filters"} onClick={()=>this.loadData(0)} disabled={this.state.loading}  >Apply filters </Button>
                    <Button className={"btn-primary"} onClick={()=>this.resetFilters()} disabled={this.state.loading} >Clear</Button>
                </div>

             </div>

              <div id={"scrollTable"} >
              <Table>
                  <Table.Header>
                      <Table.Row>
                          <Table.HeaderCell>
                              Variety
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                              Block
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                              Room
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                             Picked Date
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                              Asset Id
                          </Table.HeaderCell>
                          <Table.HeaderCell>

                          </Table.HeaderCell>
                      </Table.Row>
                  </Table.Header>

                      {!this.state.loading && this.state.uniqueItems.length > 0
                    ? ""
                    : "No data available"}

          <Table.Body>
    {  this.state.uniqueItems.map((item) => (
        <Table.Row className={!item.is_active ? "deActive" :""} key={item.qr_id}>
            <Table.Cell>{item.variety ? item.variety.name :"-"}</Table.Cell>
            <Table.Cell>{item.block ? item.block.name :"-"}</Table.Cell>
            <Table.Cell> {item.room ? this.formRoomText(item.room.name,item.row,item.column) :"-"}</Table.Cell>

            <Table.Cell>{new Date(Date.parse(item.pick_date)-new Date().getTimezoneOffset()*60*1000).toLocaleString()}</Table.Cell>
            <Table.Cell>
                {this.state.windowWidth<768 ? "..." : item.qr_id}
            </Table.Cell>
            <Table.Cell>
                <Badge href={"#"} onClick={()=>this.handleOpenHistoricalModal(item.qr_id)}>View history</Badge>
            </Table.Cell>
        </Table.Row>
    ))}
</Table.Body>


              </Table>
                  {
                      <Modal
                          className={"Modal"}
                          isOpen={this.state.showHistoricalModal}

                      >
                          <h6>Scan History</h6>
                          <div style={{overflowY:"scroll", height:"30vh"}}><Table >
                              <Table.Header>
                                  <Table.Row>
                                      <Table.HeaderCell>
                                          #
                                      </Table.HeaderCell>
                                      <Table.HeaderCell>
                                          Scanned at
                                      </Table.HeaderCell>
                                      <Table.HeaderCell>
                                          Date & Time
                                      </Table.HeaderCell>

                                  </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                  {this.state.dataForQr.map((data,index)=>(
                                      <Table.Row>
                                          <Table.Cell>
                                              {(index===0)? "Last scan" :
                                                  (index===this.state.dataForQr.length-1)?"Home scan" :
                                                      this.state.dataForQr.length-index-1}
                                          </Table.Cell>
                                          <Table.Cell>
                                              {data.block!=="-" ? data.block +" (Block)" :this.formRoomText(data.room,data.row,data.column)}
                                          </Table.Cell>
                                          <Table.Cell>
                                              {new Date(Date.parse(data.iso_date)).toLocaleString()}
                                          </Table.Cell>
                                      </Table.Row>
                                  ))}
                              </Table.Body>
                          </Table></div>

                          <div className={"alignCenter"}>
                              <Button className={"btn-primary"} onClick={this.handleCloseHistoricalModal}>Close </Button>
                          </div>
                      </Modal>


                  }
              </div>
              <ReactPaginate
                  breakLabel="..."
                  nextLabel="Next"
                  pageRangeDisplayed={10}
                  marginPagesDisplayed={1}
                  onPageChange={(e)=>this.loadData(e.selected)}
                  forcePage={this.state.currentPage}
                  pageCount={this.state.totalPages}
                  previousLabel="Previous"
                  containerClassName={"paginationBttns p-0"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
              />

          </div>
        );
    }
}
