import React, {useEffect, useState} from 'react';
import { Route, useHistory } from 'react-router-dom';
import {MsalAuthenticationTemplate, MsalProvider} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import {CustomNavigationClient} from "./helpers/NavigationClient";
import {loginRequest} from "./authConfig";
import MainLayout from './components/MainLayout';
import TenantSelectionLayout from "./components/TenantSelectionLayout";
import {getTenants} from "./helpers/api/Tenants";
import {apiConfig} from "./apiConfig";
import { AxiosProvider, Get} from 'react-axios'
import {buildAuthHeader} from "./helpers/AxiosClient";
import axios from "axios";
import Loading from "./components/Loading";
import ErrorAlert from "./components/ErrorAlert";
import PrereleaseOnlyComponent from "./components/PrereleaseOnlyComponent";
import ZendeskWidget from "./components/ZendeskWidget";
import GenerateQRCode from "./components/GenerateQRCode";
import Search from "./components/Search";
import "./App.css"

function App({ pca }) {
    //use state hooks as we are within a function component to init get and sets for tenantsData and activeTenant, farmsData and activeFarm
    const [tenantsData, setTenantsData] = useState(null);
    const [activeTenant, setActiveTenant] = useState(null);
    const [farmsData, setFarmsData] = useState(null);
    const [activeFarm, setActiveFarm] = useState(null);
    //create our customer axios
    const axiosInstance = axios.create({
        baseURL: apiConfig.endpoints.baseUrl,
    });
    axiosInstance.interceptors.request.use(async function (config) {
        config.headers.Authorization = await buildAuthHeader();
        if(activeTenant) {
            config.headers["Tenant-Id"] = activeTenant.id;
        }
        return config;
    });

    //set up navigation client to enable better history with pca
    const navigate = useHistory();
    const navigationClient = new CustomNavigationClient(navigate);
    pca.setNavigationClient(navigationClient);

    //use effect hook to execute fetch stuff without upsetting the UI
    useEffect(() => {
        //di we have tenants data?
        if (!tenantsData) {
            //nope, lets grab some
            getTenants().then(response => setTenantsData(response))
        }
        //pass in tenantsData dependency
    }, [tenantsData]);

    //return resulting template
    return (
        <MsalProvider instance={pca}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={loginRequest}
                errorComponent={e => <p>An Error Occurred: {e.error}</p>}
                loadingComponent={() => <p>Authentication in progress...</p>}
            >
                <PrereleaseOnlyComponent prereleaseComponent={<ZendeskWidget instance={pca}/>}/>
                <AxiosProvider instance={axiosInstance}>
                    <Get url={apiConfig.endpoints.tenants.getAll}>
                        {(error, response, isLoading, makeRequest, axios) => {
                            if(error) {
                                console.error("[IAG-ERROR] error during tenant call: " + error.message)
                                return (<ErrorAlert message={"Our systems may be undergoing maintenance, please try again in a while or press the help button in the bottom corner to contact support."} actionDisplayName={"Retry"} actionMethod={() => makeRequest({ params: { reload: true } })} />)
                            }
                            else if(isLoading) {
                                return (<Loading />)
                            }
                            else if(response !== null) {
                                setTenantsData(response.data);
                                return (<div>
                                    {
                                        activeTenant
                                            ?
                                            <MainLayout tenantsData={tenantsData} setTenantsData={setTenantsData} activeTenant={activeTenant} setActiveTenant={setActiveTenant} farmsData={farmsData} setFarmsData={setFarmsData} activeFarm={activeFarm} setActiveFarm={setActiveFarm}>
                                                <Route exact path='/' component={() => <Search tenantsData={tenantsData} activeTenant={activeTenant} farmsData={farmsData} activeFarm={activeFarm} axios={axios}/>}/>
                                                <Route path='/generate' component={() => <GenerateQRCode activeTenant={activeTenant} axios={axios} farmsData={farmsData} activeFarm={activeFarm}/>}/>
                                            </MainLayout>
                                            :
                                            <TenantSelectionLayout tenantsData={tenantsData} setActiveTenant={setActiveTenant} refreshTenants={ () => getTenants().then(response => setTenantsData(response)) } />
                                    }
                                </div>)
                            }
                            return (<Loading />)
                        }}
                    </Get>
                </AxiosProvider>
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
}

export default App;