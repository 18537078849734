import React from 'react';
import "./Loading.css";

export default function (){
    return(
        <div id={"loading-container"} className={"text-center"}>
            <img src="images/logos-03.png" width="280" alt={"InformAg Logo"} />
            <div className='pulse-container'>
                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 36 36" fill="none">
                    <path d="M3.79102 4.73636V14.21C3.79102 14.7334 4.21527 15.1573 4.7383 15.1573C5.26167 15.1573 5.68558 14.7334 5.68558 14.21V5.68373H15.1592V14.21C15.1592 14.7334 15.5835 15.1573 16.1069 15.1573C16.6299 15.1573 17.0541 14.7334 17.0541 14.21V4.73636C17.0541 4.48524 16.9543 4.24404 16.7766 4.06661C16.5988 3.88884 16.358 3.78906 16.1068 3.78906H4.73845C4.2154 3.78906 3.79117 4.21331 3.79117 4.73634L3.79102 4.73636Z" fill="#000000"/>
                    <path d="M8.52541 7.57912C8.00203 7.57912 7.57812 8.00304 7.57812 8.5264V12.3159C7.57812 12.5671 7.67791 12.8083 7.85567 12.9857C8.03311 13.1635 8.2743 13.2632 8.52542 13.2632H12.315C12.5661 13.2632 12.8073 13.1635 12.9847 12.9857C13.1625 12.8083 13.2623 12.5671 13.2623 12.3159V8.5264C13.2623 8.27528 13.1625 8.03408 12.9847 7.85664C12.8073 7.67888 12.5661 7.5791 12.315 7.5791L8.52541 7.57912ZM11.3677 11.3687H9.47277V9.47377H11.3673L11.3677 11.3687Z" fill="#000000"/>
                    <path d="M4.7383 20.8418C4.21525 20.8418 3.79102 21.2657 3.79102 21.7891V31.2628C3.79102 31.5139 3.8908 31.7551 4.06856 31.9325C4.246 32.1103 4.48719 32.2101 4.73831 32.2101H16.1067C16.3578 32.2101 16.5987 32.1103 16.7765 31.9325C16.9542 31.7551 17.054 31.5139 17.054 31.2628V21.7891C17.054 21.2657 16.6297 20.8418 16.1067 20.8418C15.5833 20.8418 15.1591 21.2657 15.1591 21.7891V30.3154H5.68544V21.7891C5.68544 21.5376 5.58566 21.2968 5.40823 21.119C5.23046 20.9416 4.98959 20.8418 4.73814 20.8418L4.7383 20.8418Z" fill="#000000"/>
                    <path d="M12.315 28.4214C12.5661 28.4214 12.8073 28.3217 12.9847 28.1439C13.1625 27.9665 13.2623 27.7253 13.2623 27.4741V23.6846C13.2623 23.4335 13.1625 23.1923 12.9847 23.0148C12.8073 22.8371 12.5661 22.7373 12.315 22.7373H8.52541C8.00203 22.7373 7.57812 23.1616 7.57812 23.6846V27.4741C7.57812 27.7253 7.67791 27.9665 7.85567 28.1439C8.03311 28.3217 8.2743 28.4214 8.52542 28.4214L12.315 28.4214ZM9.47269 24.6319H11.3673V26.5265L9.47269 26.5268V24.6319Z" fill="#000000"/>
                    <path d="M18.9473 31.2629C18.9473 31.5141 19.0471 31.7553 19.2248 31.9327C19.4026 32.1105 19.6434 32.2102 19.8946 32.2102H25.579C26.1021 32.2102 26.5263 31.786 26.5263 31.263C26.5263 30.7396 26.102 30.3157 25.579 30.3157H20.8422V28.8945C20.8422 28.3712 20.4179 27.9473 19.8946 27.9473C19.3715 27.9473 18.9473 28.3712 18.9473 28.8945L18.9473 31.2629Z" fill="#000000"/>
                    <path d="M19.8945 27.0003C20.146 27.0003 20.3869 26.9005 20.5646 26.7231C20.7424 26.5454 20.8422 26.3045 20.8422 26.053V21.7901C20.8422 21.2667 20.4179 20.8428 19.8945 20.8428C19.3715 20.8428 18.9473 21.2667 18.9473 21.7901V26.053C18.9473 26.3045 19.0471 26.5454 19.2248 26.7231C19.4026 26.9006 19.6434 27.0003 19.8946 27.0003H19.8945Z" fill="#000000"/>
                    <path d="M31.2637 24.1585C31.5148 24.1585 31.756 24.0587 31.9334 23.8809C32.1112 23.7032 32.211 23.4623 32.211 23.2112V21.7901C32.211 21.2667 31.7867 20.8428 31.2637 20.8428C30.7403 20.8428 30.3164 21.2667 30.3164 21.7901V23.2112C30.3164 23.4623 30.4162 23.7032 30.5936 23.8809C30.7714 24.0587 31.0123 24.1585 31.2637 24.1585H31.2637Z" fill="#000000"/>
                    <path d="M28.4199 30.3158C27.8969 30.3158 27.4727 30.7397 27.4727 31.2631C27.4727 31.7861 27.8969 32.2104 28.4199 32.2104H31.2622C31.5133 32.2104 31.7545 32.1106 31.932 31.9328C32.1097 31.7554 32.2095 31.5142 32.2095 31.2631V30.3158C32.2095 29.7924 31.7852 29.3682 31.2622 29.3682C30.7388 29.3682 30.3149 29.7924 30.3149 30.3158L28.4199 30.3158Z" fill="#000000"/>
                    <path d="M19.8944 15.1573C20.1458 15.1573 20.3867 15.0575 20.5645 14.8801C20.7422 14.7023 20.842 14.4615 20.842 14.21V5.68373H30.3157V14.21C30.3157 14.7334 30.7396 15.1573 31.263 15.1573C31.786 15.1573 32.2102 14.7334 32.2102 14.21V4.73636C32.2102 4.48524 32.1104 4.24404 31.9327 4.06661C31.7553 3.88884 31.5141 3.78906 31.2629 3.78906H19.8945C19.3715 3.78906 18.9473 4.21331 18.9473 4.73634V14.21C18.9473 14.4615 19.0471 14.7023 19.2248 14.8801C19.4026 15.0575 19.6434 15.1573 19.8946 15.1573L19.8944 15.1573Z" fill="#000000"/>
                    <path d="M23.6836 7.57912C23.1606 7.57912 22.7363 8.00304 22.7363 8.5264V12.3159C22.7363 12.5671 22.8361 12.8083 23.0139 12.9857C23.1913 13.1635 23.4325 13.2632 23.6836 13.2632H27.4732C27.7243 13.2632 27.9655 13.1635 28.1429 12.9857C28.3207 12.8083 28.4205 12.5671 28.4205 12.3159V8.5264C28.4205 8.27528 28.3207 8.03408 28.1429 7.85664C27.9655 7.67888 27.7243 7.5791 27.4732 7.5791L23.6836 7.57912ZM26.5259 11.3687H24.631V9.47377H26.5255L26.5259 11.3687Z" fill="#000000"/>
                    <path d="M22.7363 21.7891C22.213 21.7891 21.7891 22.213 21.7891 22.7364V26.0521C21.7891 26.3035 21.8889 26.5444 22.0666 26.7221C22.244 26.8996 22.4852 26.9994 22.7364 26.9994C22.8163 26.998 22.8959 26.9868 22.9733 26.9663C23.0506 26.9868 23.1299 26.998 23.2102 26.9994H24.1575V28.4205C24.1575 28.9439 24.5814 29.3678 25.1047 29.3678C25.6281 29.3678 26.052 28.9438 26.052 28.4205V26.0521C26.052 25.801 25.9522 25.5598 25.7745 25.3823C25.597 25.2046 25.3558 25.1048 25.1047 25.1048H23.6836V22.7364C23.6836 22.4852 23.5838 22.244 23.406 22.0666C23.2286 21.8888 22.9874 21.7891 22.7363 21.7891L22.7363 21.7891Z" fill="#000000"/>
                    <path d="M28.4204 21.7891H25.5785C25.0551 21.7891 24.6309 22.213 24.6309 22.7363C24.6309 23.2597 25.0551 23.6836 25.5785 23.6836H28.4207H28.4204C28.9438 23.6836 29.3677 23.2597 29.3677 22.7363C29.3677 22.213 28.9438 21.7891 28.4204 21.7891H28.4204Z" fill="#000000"/>
                    <path d="M30.3164 27.4739C30.3164 27.9973 30.7403 28.4212 31.2637 28.4212C31.7867 28.4212 32.211 27.9972 32.211 27.4739V26.0528C32.211 25.5297 31.7867 25.1055 31.2637 25.1055C30.7403 25.1055 30.3164 25.5297 30.3164 26.0528V27.4739Z" fill="#000000"/>
                    <path d="M28.4199 29.3687C28.6714 29.3687 28.9122 29.2689 29.09 29.0915C29.2674 28.9137 29.3672 28.6728 29.3672 28.4214V25.5795C29.3672 25.0561 28.9433 24.6318 28.4199 24.6318C27.8969 24.6318 27.4727 25.0561 27.4727 25.5795V28.4217V28.4214C27.4727 28.6728 27.5724 28.9137 27.7502 29.0915C27.9276 29.2689 28.1688 29.3687 28.42 29.3687L28.4199 29.3687Z" fill="#000000"/>
                    <path d="M0.947273 9.94744C1.19872 9.94744 1.43959 9.84766 1.61736 9.6699C1.79512 9.49213 1.8949 9.25126 1.8949 9.00014V1.8949H9.00014C9.52319 1.8949 9.94742 1.47065 9.94742 0.947281C9.94742 0.424234 9.52317 0 9.00014 0H0.947282C0.424234 0 0 0.42425 0 0.947281V9.00014C0 9.25126 0.0997867 9.49213 0.277545 9.6699C0.455311 9.84766 0.696179 9.94744 0.947298 9.94744H0.947273Z" className={"outer-scan"} fill="#d32f2f"/>
                    <path d="M27 36.0002H35.0529C35.304 36.0002 35.5448 35.9004 35.7226 35.7226C35.9004 35.5449 36.0002 35.304 36.0002 35.0529V27C36.0002 26.477 35.5759 26.0527 35.0529 26.0527C34.5295 26.0527 34.1053 26.477 34.1053 27V34.1053H27C26.477 34.1053 26.0527 34.5295 26.0527 35.0529C26.0527 35.5759 26.477 36.0002 27 36.0002L27 36.0002Z" className={"outer-scan"} fill="#d32f2f"/>
                    <path d="M0.94728 36.0002H9.00014C9.52319 36.0002 9.94742 35.5759 9.94742 35.0529C9.94742 34.5295 9.52317 34.1053 9.00014 34.1053H1.8949V27C1.8949 26.477 1.47065 26.0527 0.94728 26.0527C0.424233 26.0527 0 26.477 0 27V35.0529C0 35.304 0.0997854 35.5449 0.277543 35.7226C0.45531 35.9004 0.696179 36.0002 0.947298 36.0002L0.94728 36.0002Z" className={"outer-scan"} fill="#d32f2f"/>
                    <path d="M27 1.89492H34.1053V9.00016C34.1053 9.52321 34.5295 9.94744 35.0529 9.94744C35.5759 9.94744 36.0002 9.52319 36.0002 9.00016V0.947298C36.0002 0.696179 35.9004 0.455311 35.7226 0.277545C35.5449 0.0997783 35.304 0 35.0529 0H27C26.477 0 26.0527 0.424251 26.0527 0.947282C26.0527 1.47066 26.477 1.8949 27 1.8949L27 1.89492Z" className={"outer-scan"} fill="#d32f2f"/>
                    <path d="M0.94728 18.9473H35.0524C35.5755 18.9473 35.9997 18.5234 35.9997 18C35.9997 17.4767 35.5755 17.0527 35.0524 17.0527H0.94728C0.424233 17.0527 0 17.4767 0 18C0 18.5234 0.42425 18.9473 0.94728 18.9473Z" id="scan"/>
                </svg>
            </div>
        </div>

    );
}