import React from 'react';
import logo from '../images/csv.png';


const ExcelDataDownload = props => {
    const { downloadAsCSV,loading } = props;

    return (

        <div>
            <button disabled={loading} type={"button"} title="Export to CSV" className={"btn-primary float-right btn btn-secondary ml-1"}
                        onClick={downloadAsCSV}>Export CSV</button>
        </div>
    );
}

export default ExcelDataDownload;
