import React from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import TenantSelectionMenu from "./TenantSelectionMenu";
//import font awesome and solid icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
//add all solid icons to library for use throughout whole app
library.add(fas);

function TenantSelectionLayout({tenantsData, setActiveTenant, refreshTenants}) {
    return (
        <div>
            <div className="d-flex" id="wrapper">
                <div id="page-content-wrapper" className="vh-100">
                    <NavMenu />
                    <Container fluid={true}>
                        {tenantsData
                            ? tenantsData.length<2
                                ? setActiveTenant({id: tenantsData[0].id, name: tenantsData[0].name})
                                : <TenantSelectionMenu tenantsData={tenantsData} setActiveTenant={setActiveTenant} />
                            : <div>No tenant data, sad. <button onClick={refreshTenants}>Refresh</button></div>
                        }
                    </Container>
                </div>
            </div>
        </div>
    );
}

export default TenantSelectionLayout;
